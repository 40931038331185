.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: CALC( 100vh - 100px) ;  /* change this to whatever you want */
    width: auto;
}


.SrvSubmitBt {
    margin-bottom: 50px;
  }